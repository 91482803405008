import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);



//公共img组件
import BaseImg from '@/components/BaseImg.vue';
Vue.component('BaseImg', BaseImg);// 全局注册使用

//无数据组件
import NoData from '@/components/NoData.vue';
Vue.component('NoData', NoData);// 全局注册使用

import formateTel from './utils/formateTel.js';
import trim from './utils/trim.js';

Vue.prototype.$formateTel = formateTel;//格式化手机号
Vue.prototype.$trim = trim;//去除空格

//引入全局路由守卫
import './permission'
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
