<template>
    <div class="base-img">
        <template v-if='!imgErr' >
            <img :src='src' class="fill" :class="[imgLoaded ? 'imgAnt':'']" @load='loadImg' @error="imgError()" loading="lazy"  v-if="mode == 'fill'"/>
            <img :src='src' class="cover" :class="[imgLoaded ? 'imgAnt':'']" @load='loadImg' @error="imgError()" loading="lazy"  v-if="mode == 'cover'"/>
            <img :src='src' class="contain" :class="[imgLoaded ? 'imgAnt':'']" @load='loadImg' @error="imgError()" loading="lazy"  v-if="mode == 'contain'"/>
        </template>
        
        <div class="loading-bg" v-if='!imgLoaded'>
            <!-- <uni-icons type='image' size="40" color='#eee'></uni-icons> -->
        </div>
        <div class="err-img" v-if='imgErr'>
            <img src='../assets/img/loadFail.png' />
        </div>
    </div>

</template>

<script>
export default {
    name: "BaseImg",
    props: {
        src: {
            type: String,
            default: ''
        },
        mode:{
            type:String,
            default:'cover'
            // fill ---【默认值】
            // cover --- 图像保持宽高比，并填充给定的尺寸，但是图片将会被裁剪来适应给定的宽高比, 图片太大时会从中间裁剪。
            // contain --- 保持宽高比，但是图片会被缩放，来适应给定的最小宽高：
        }
    },
    data() {
        return {
            imgLoaded: false,
            imgErr: false,
            imgSrc: ''
        };
    },
    methods: {
        loadImg(e) {
            if (e) {
                this.imgLoaded = true;
            }
        },
        imgError() {
            // this.imgLoaded = false;
            this.imgErr = true;
        }

    }
};
</script>

<style lang='scss' scoped>
.base-img {
    width: 100%;
    height: 100%;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        opacity: 0.2;
        position: relative;
        z-index: 2;
    }
}
.fill{
    object-fit: fill;
}
.contain {
    object-fit: contain;
}

.cover {
    object-fit: cover;
}
.imgAnt {
    opacity: 1 !important;
    transition-duration: 1s;
    transition-property: opacity;
}
.loading-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    // background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.err-img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 30px;
        height: 30px;
        opacity: 1;
    }
}
</style>