<template>
    <div class="no-data-wrap">
        <img :src='noDataImg' />
        <p>{{ text }}</p>
    </div>
</template>

<script>
export default {
    name: "NoData",
    props: {
        text: {
            type: String,
            default: '暂无数据'
        },
        noDataImg: {
            type: String,
            default: require('../assets/img/no-data.png')
        }
    },
    methods: {

    },
};
</script>

<style lang='scss' scoped>
.no-data-wrap {
    width: 300px;
    margin: auto;
    img {
        width: 300px;
        height: 300px;
    }
}
p {
    text-align: center;
    font-size: 14px;
    color:#AFB0B2;
}
</style>