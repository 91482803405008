import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/IndexView.vue'),
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('../views/home/IndexView.vue'),
                meta: {
                    // requireAuth: true,
                    title: '首页'
                }
            },
            {
                path: '/map',
                name: 'map',
                component: () => import('../views/home/Map.vue'),
                meta: {
                    // requireAuth: true,
                    title: '地图'
                }
            },
            {
                path: '/goods_detail',
                name: 'goods_detail',
                component: () => import('../views/goods/GoodsDetail.vue'),
                meta: {
                    // requireAuth: true,
                    title: '商品详情'
                }
            },
            {
                path: '/search',
                name: 'search',
                component: () => import('../views/goods/SearchPage.vue'),
                meta: {
                    // requireAuth: true,
                    title: '搜索'
                }
            },
            {
                path: '/activity_detail/:id',
                name: 'activity_detail',
                component: () => import('../views/goods/ActivityDetail.vue'),
                meta: {
                    // requireAuth: true,
                    title: '活动详情'
                }
            },
            {
                path: '/cart',
                name: 'cart',
                component: () => import('../views/goods/MyCart.vue'),
                meta: {
                    requireAuth: true,
                    title: '购物车'
                }
            },
            {
                path: '/category/:id/:name',
                name: 'category',
                component: () => import('../views/goods/CategoryPage.vue'),
                meta: {
                    // requireAuth: true,
                    title: '分类'
                }
            },
            {
                path: '/more_goods_list',
                name: 'more_goods_list',
                component: () => import('../views/goods/MoreGoodsList.vue'),
                meta: {
                    // requireAuth: true,
                    title: '商品列表'
                }
            },
            {
                path: '/order/submit_order/:ids',
                name: 'submit_order',
                component: () => import('../views/order/SubmitOrder.vue'),
                meta: {
                    requireAuth: true,
                    title: '提交订单'
                }
            },
            {
                path: '/order/order_detail/:id',
                name: 'order_detail',
                component: () => import('../views/order/OrderDetail.vue'),
                meta: {
                    requireAuth: true,
                    title: '订单详情'
                }
            },
            {
                path: '/order/package_detail/:id',
                name: 'order_detail',
                component: () => import('../views/order/PackageDetail.vue'),
                meta: {
                    requireAuth: true,
                    title: '包裹详情'
                }
            },
            {
                path: '/user',
                name: 'user',
                component: () => import('../views/user/IndexView.vue'),
                meta: {
                    requireAuth: true,
                    title: '个人中心'
                },
                children: [
                    {
                        path: '/user/order/:type',
                        name: 'order',
                        component: () => import('../views/user/OrderList.vue'),
                        props: true,
                        meta: {
                            requireAuth: true,
                            title: '我的订单',
                            navIndex: 0,
                        },
                    },
                    {
                        path: '/user/bill',
                        name: 'bill',
                        component: () => import('../views/user/BillList.vue'),
                        meta: {
                            requireAuth: true,
                            title: '我的账单',
                            navIndex: 1,
                        }
                    },
                    {
                        path: '/user/address',
                        name: 'address',
                        component: () => import('../views/user/AddressList.vue'),
                        meta: {
                            requireAuth: true,
                            title: '收货地址',
                            navIndex: 2,
                        }
                    },
                    {
                        path: '/user/collect',
                        name: 'collect',
                        component: () => import('../views/user/CollectList.vue'),
                        meta: {
                            requireAuth: true,
                            title: '我的收藏',
                            navIndex: 3,
                        }
                    },
                    {
                        path: '/user/foot',
                        name: 'foot',
                        component: () => import('../views/user/CollectList.vue'),
                        meta: {
                            requireAuth: true,
                            title: '我的足迹',
                            navIndex: 4,
                        }
                    },
                    {
                        path: '/user/search_goods',
                        name: 'search_goods',
                        component: () => import('../views/user/SearchGoods.vue'),
                        meta: {
                            requireAuth: true,
                            title: '找货服务',
                            navIndex: 5,
                        }
                    },
                    {
                        path: '/user/finance',
                        name: 'finance',
                        component: () => import('../views/user/FinancePage.vue'),
                        meta: {
                            requireAuth: true,
                            title: '金融服务',
                            navIndex: 6,
                        }
                    },
                    {
                        path: '/user/enterprise_auth',
                        name: 'enterprise_auth',
                        component: () => import('../views/user/EnterpriseAuth.vue'),
                        meta: {
                            requireAuth: true,
                            title: '企业认证',
                            navIndex: 7,
                        }
                    },
                    {
                        path: '/user/bill_detail',
                        name: 'bill_detail',
                        component: () => import('../views/user/BillDetail.vue'),
                        meta: {
                            requireAuth: true,
                            title: '账单详情',
                            navIndex: 1,
                        }
                    }
                ]
            }

        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginView.vue'),
        meta: {
            requireAuth: false
        }
    },
    {
        path:'*',
        name: 'not_found',
        component: () => import('../views/404.vue'),
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        // 如果有savedPosition，就跳转到该位置
        if (savedPosition) {
            return savedPosition
        } else {
            // 如果没有，就返回顶部
            return { x: 0, y: 0 }
        }
    }
})

export default router
