import router from './router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({
    easing: 'ease', // 动画方式
    speed: 500, // 递增进度条的速度
    showSpinner: false, // 是否显示加载ico
    trickleSpeed: 200, // 自动递增间隔
    minimum: 0.3 // 初始化时的最小百分比
})
// 路由拦截器
router.beforeEach((to, from, next) => {
    NProgress.start();
    if (to.meta.requireAuth) {//判断是否需要登录权限
        if (window.localStorage.getItem("Client-Access-Token")) {
            next();
        } else {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                } // 将跳转的路由path作为参数，登录成功后跳转到该路由
            })
        }
    }else{
        next();
    }

})
router.afterEach((to) => {
    NProgress.done()
})