import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isClosePoster:0,
        siteInfo:{}
    },
    getters: {
    },
    mutations: {
        SET_POSTER_CLOSE(state,newVal){
            state.isClosePoster = newVal;
        },
        SET_SITE_INFO(state,newVal){
            state.siteInfo = newVal;
        }
    },
    actions: {
    },
    modules: {
    }
})
